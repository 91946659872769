import { useEffect } from "react";
import "./App.css";
import logo from "./logo.png";
import "remixicon/fonts/remixicon.css";
import ReactGA from "react-ga";

function getQueryParam(param) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
}

function App() {
  const tripId = getQueryParam("tripId");
  const basic_cred = getQueryParam("basic_cred");

  useEffect(() => {
    ReactGA.initialize("G-8D3LFMPZX7");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <div className="iframeContainer">
        <iframe
          src={`https://sct.intutrack.com/#!/public-new?tripId=${tripId}&basic_cred=${basic_cred}`}
          title="NowPurchase tracking"
        ></iframe>
        <div className="sticker">
          <img className="stickerLogo" src={logo} alt=""></img>
          <div className="iconContainer">
            <i className="ri-windy-line wind"></i>
            <i className="ri-truck-fill truck"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
